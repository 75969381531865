<template>
  <div class="aboutOurCompany">
    <div class="title"><h3>تنقيه مياه الشرب</h3></div>

    <v-divider></v-divider>
    <v-container>
      <div>
        <v-row><h2 class="mt-10">المهام الرئيسيه لتنقيه مياه الشرب</h2></v-row>
        <v-row>
          <v-col cols="12" lg="8" sm="10" xs="10">
            <v-expansion-panels v-model="panel" multiple>
              <v-expansion-panel
                v-for="(item, i) in ArrayOFAnElsherka"
                :key="i"
                class="changeTitleStyle"
              >
                <v-expansion-panel-header expand-icon="mdi-menu-down">
                  {{ item.title }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <h4 class="importantSite" v-if="item.text">
                    {{ item.text }}
                  </h4>
                  <h3 class="brown--text" v-if="item.text2">
                    {{ item.text2 }}
                  </h3>
                  <ul v-if="item.subtext">
                    <li v-for="i in item.subtext" :key="i">{{ i }}</li>
                  </ul>
                  <h3 class="brown--text" v-if="item.text3">
                    {{ item.text3 }}
                  </h3>
                  <h4 v-if="item.text4">{{ item.text4 }}</h4>
                  <h4 v-if="item.text5">{{ item.text5 }}</h4>
                  <h4 v-if="item.text6">{{ item.text6 }}</h4>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col cols="12" lg="4" sm="4" xs="4">
            <Link></Link>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
import Link from "../../components/link.vue";
export default {
  components: { Link },
  data() {
    return {
      panel: [0],
      ArrayOFAnElsherka: [
        {
          title: "الهدف من تنقية المياه",
          text: "يقصد بالتنقية التخلص من كل أو بعض المواد الغريبة الدخيلة على المياه سواء كانت ذائبة أو عالقة أو غروية ، حيث أن المياه السطحية معرضة لعوامل كثيرة تؤدي إلي تلوثها فتصبح غير صالحة للاستعمال إلا بعد تنقيتها",
        },
        {
          title: "تقسيم عمليات التنقية",
          text: "ويمكن تقسيم المياه طبقاً لدرجة نقائها إلى",
          subtext: ["التنقية لأغراض الشرب", "التنقية لأغراض الصناعة"],
        },
        {
          title: "انواع تطهير المياة",
          subtext: ["معالجة اشعاعية ", "معالجة حرارية ", "معالجة كيميائية"],
        },
        {
          title: "التطهير بالكلور",
          text: "أكثر الطرق شيوعا في عمليات تطهير المياه هي إضافة الكلور حيث  أنه يقضى بدرجة كبيرة على الأمراض التي ينقلها الماء وذلك بأقل التكاليف وأبسط المعدات وأقل عدد من العاملين ولولا عملية الكلورة لانتشرت اوبئه الكوليرا والتيفود. ويتميز التطهير بواسطة الكلور بسهولة استعماله ، وكذلك سهولة الحكم على مدى فاعليته بالتأكد من بقاء قدرا من الكلور في الماء بعد فترة من إضافته؛ حيث أن الكلورين عامل مؤكسد قوي وعندما يستخدم بكميات كافية فإنه يوقف نمو الطحالب في المرشحات",
          text2:
            "العوامل المؤثرة في عملية التطهير (فاعلية الكلور) في قتل البكتريا",
          text3: "جرعه الكلور",
          subtext: [
            "درجة الحرارة ",
            "مدة التفاعل بين الكلور والماء (فترة التلامس) ",
            " درجة تركيز أيون الهيدروجين",
            "عكارة الماء",
            " قلوية وحامضية الماء",
            "وجود مركبات الحديد والمنجنيز",
            " نوع وعدد البكتريا المراد القضاء عليها",
            "إضافة الكلور",
          ],
          text4:
            "ويتم تحديد الجرعة المثلى للكلور عن طريق تجارب معملية حسب نوعية المياه المراد معالجتها ، ويمكن القول أنه : إذا أضفنا كمية الكلورين اللازمة لحد الطلب + كمية الكلورين اللازمة للتطهير فإننا نحصل على ما يسمى بجرعة الكلور .",
          text5:
            "إضافة الكلور المبدئي  -:أي حقن الكلور بعد عملية تجميع المياه من المصدر مباشرة  وقبل الدخول الى عملية التنقية (المروقات والمرشحات).",
          text6:
            "إضافة الكلور النهائي: أي إضافة الكلور إلى الماء بعد مرحلة الترشيح أي عند مدخل خزان المياه النقية ، وهي طريقة سهلة في تشغيلها ويكون الكلور اكثر فاعلية على البكتريا بسبب خلو الماء من أي عكارة أو شوائب .ويضاف جرعة زائدة من الكلور النهائي في الخزانات الملحقة بالمحطات وذلك لضمان خروج الكلور بنسبة معينة مطلوبة إذا كانت شبكات مواسير التوزيع تمتد إلى مسافات بعيدة ويخشي من تواجد البكتريا في الأطراف البعيدة منها وحتى يصل الكلور ولو بنسبة ضئيلة إلى آخر متر في الشبكة .",
        },
        {
          title: "كيماويات الترسيب (كبريتات الألومنيوم)",
          text3:
            "تعرف عادة باسم الشبة ، وحتى يكون التعبير دقيقا فهى خليط من الكبريتات المعدنية المتنوعة- يصل وزنها إلى 480 كجم/م3 ويمكن الحصول عليها فى أكياس تزن من 40-50 كجم ،على شكل كتل أو حبيبات أو مسحوق .",
          text4:
            "والشبة المستخدمة فى محطات المياه تحتوى على 14-16٪ من أكسيد الألومنيوم ،بالإضافة إلى ماء التبلور ، ولهذا فإن كبريتات الألومنيوم الفعالة تكون نسبتها فى الوزن 84-86٪.",
          text5:
            "والشبة سريعة الذوبان فى الماء ، وينتج محلول حمضى يتطلب استخدام المواد المقاومة للتآكل فى الأحواض والمضخات وشبكات المواسير.",
          text6:
            "وللتوصل إلى مزج سليم يفضل أن لا تزيد قوة تركيز المحلول فى الجرعات المضافة عن 10٪.",
        },
      ],
    };
  },
};
</script>
<style >
h3 {
  font-family: Arial, Helvetica, sans-serif;
}
.changeTitleStyle {
  background: rgba(222, 184, 135, 0.192) !important;
}
</style>